import { spawn } from 'child_process';
import { FC, useState } from 'react';

//make an infterface for the props
interface groupsProps {}
const Mail: FC<groupsProps> = ({}) => {
  const [selectedLists, setSelectedLists] = useState<number[]>([]);
  return (
    <>
      <div className="relative z-10 w-full p-3 pb-10 md:pt-10 md:p-5 lg:pl-10 xl:pl-16 lg:pb-10 md:pb-10 xl:pb-16">
        <div className="mb-10">
          <h1 className="text-2xl font-bold text-[#143588]">MANUPORTmail</h1>
        </div>
        <div className="mb-10">
          <img className="inline-block" src="./suzy-mail.jpg" alt="Suzy Mail" />
        </div>
        <div className="grid lg:grid-cols-5">
          <div>
            <h3 className="font-bold text-[#143588] mb-10 lg:mb-0">Send to</h3>
          </div>
          <div className="grid col-span-3 gap-3 mb-10 lg:grid-cols-3 lg:mb-0">
            <div>
              <a
                onClick={() => {
                  if (selectedLists.includes(1)) {
                    setSelectedLists(
                      selectedLists.filter((list) => list !== 1)
                    );
                  } else {
                    setSelectedLists([...selectedLists, 1]);
                  }
                }}
                className="bg-[#143588]  text-sm cursor-pointer transition-all hover:bg-slate-400 text-white px-4 inline-flex py-1.5 rounded-full  gap-3 items-center"
              >
                <span>CONTACTS 1</span>

                {selectedLists.includes(1) ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2.5}
                    stroke="currentColor"
                    className="w-4 h-5 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                ) : (
                  <span className="w-4 h-4 border rounded-xs"></span>
                )}
              </a>
            </div>
            <div>
              <a
                onClick={() => {
                  if (selectedLists.includes(2)) {
                    setSelectedLists(
                      selectedLists.filter((list) => list !== 2)
                    );
                  } else {
                    setSelectedLists([...selectedLists, 2]);
                  }
                }}
                className="bg-[#143588] text-sm cursor-pointer transition-all hover:bg-slate-400 text-white px-4 inline-flex py-1.5 rounded-full  gap-3 items-center"
              >
                <span>CONTACTS 2</span>

                {selectedLists.includes(2) ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2.5}
                    stroke="currentColor"
                    className="w-4 h-5 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                ) : (
                  <span className="w-4 h-4 border rounded-xs"></span>
                )}
              </a>
            </div>
            <div>
              <a
                onClick={() => {
                  if (selectedLists.includes(3)) {
                    setSelectedLists(
                      selectedLists.filter((list) => list !== 3)
                    );
                  } else {
                    setSelectedLists([...selectedLists, 3]);
                  }
                }}
                className="bg-[#143588] text-sm cursor-pointer transition-all hover:bg-slate-400 text-white px-4 inline-flex py-1.5 rounded-full  gap-3 items-center"
              >
                <span>CONTACTS 3</span>

                {selectedLists.includes(3) ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2.5}
                    stroke="currentColor"
                    className="w-4 h-5 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                ) : (
                  <span className="w-4 h-4 border rounded-xs"></span>
                )}
              </a>
            </div>
            <div>
              <a
                onClick={() => {
                  if (selectedLists.includes(4)) {
                    setSelectedLists(
                      selectedLists.filter((list) => list !== 4)
                    );
                  } else {
                    setSelectedLists([...selectedLists, 4]);
                  }
                }}
                className="bg-[#143588] text-sm cursor-pointer transition-all hover:bg-slate-400 text-white px-4 inline-flex py-1.5 rounded-full  gap-3 items-center"
              >
                <span>CONTACTS 4</span>

                {selectedLists.includes(4) ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2.5}
                    stroke="currentColor"
                    className="w-4 h-5 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                ) : (
                  <span className="w-4 h-4 border rounded-xs"></span>
                )}
              </a>
            </div>
            <div>
              <a
                onClick={() => {
                  if (selectedLists.includes(8)) {
                    setSelectedLists(
                      selectedLists.filter((list) => list !== 8)
                    );
                  } else {
                    setSelectedLists([...selectedLists, 8]);
                  }
                }}
                className="bg-[#143588] text-sm cursor-pointer transition-all hover:bg-slate-400 text-white px-4 inline-flex py-1.5 rounded-full  gap-3 items-center"
              >
                <span>CONTACTS 5</span>

                {selectedLists.includes(8) ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2.5}
                    stroke="currentColor"
                    className="w-4 h-5 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                ) : (
                  <span className="w-4 h-4 border rounded-xs"></span>
                )}
              </a>
            </div>
            <div>
              <a
                onClick={() => {
                  if (selectedLists.includes(9)) {
                    setSelectedLists(
                      selectedLists.filter((list) => list !== 9)
                    );
                  } else {
                    setSelectedLists([...selectedLists, 9]);
                  }
                }}
                className="bg-[#143588] text-sm cursor-pointer transition-all hover:bg-slate-400 text-white px-4 inline-flex py-1.5 rounded-full  gap-3 items-center"
              >
                <span>CONTACTS 6</span>

                {selectedLists.includes(9) ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2.5}
                    stroke="currentColor"
                    className="w-4 h-5 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                ) : (
                  <span className="w-4 h-4 border rounded-xs"></span>
                )}
              </a>
            </div>
            <div>
              <a
                onClick={() => {
                  if (selectedLists.includes(5)) {
                    setSelectedLists(
                      selectedLists.filter((list) => list !== 5)
                    );
                  } else {
                    setSelectedLists([...selectedLists, 5]);
                  }
                }}
                className="bg-[#143588] text-sm cursor-pointer transition-all hover:bg-slate-400 text-white px-4 inline-flex py-1.5 rounded-full  gap-3 items-center"
              >
                <span>PRESS BE</span>

                {selectedLists.includes(5) ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2.5}
                    stroke="currentColor"
                    className="w-4 h-5 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                ) : (
                  <span className="w-4 h-4 border rounded-xs"></span>
                )}
              </a>
            </div>
            <div>
              <a
                onClick={() => {
                  if (selectedLists.includes(6)) {
                    setSelectedLists(
                      selectedLists.filter((list) => list !== 6)
                    );
                  } else {
                    setSelectedLists([...selectedLists, 6]);
                  }
                }}
                className="bg-[#143588] text-sm cursor-pointer transition-all hover:bg-slate-400 text-white px-4 inline-flex py-1.5 rounded-full  gap-3 items-center"
              >
                <span>PRESS FR</span>

                {selectedLists.includes(6) ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2.5}
                    stroke="currentColor"
                    className="w-4 h-5 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                ) : (
                  <span className="w-4 h-4 border rounded-xs"></span>
                )}
              </a>
            </div>
            <div>
              <a
                onClick={() => {
                  if (selectedLists.includes(7)) {
                    setSelectedLists(
                      selectedLists.filter((list) => list !== 7)
                    );
                  } else {
                    setSelectedLists([...selectedLists, 7]);
                  }
                }}
                className="bg-[#143588] text-sm cursor-pointer transition-all hover:bg-slate-400 text-white px-4 inline-flex py-1.5 rounded-full  gap-3 items-center"
              >
                <span>PRESS ENG</span>

                {selectedLists.includes(7) ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2.5}
                    stroke="currentColor"
                    className="w-4 h-5 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                ) : (
                  <span className="w-4 h-4 border rounded-xs"></span>
                )}
              </a>
            </div>
          </div>
          <div className="flex flex-wrap content-end gap-3 lg:justify-end">
            <div>
              <a className="bg-[#143588] text-sm cursor-pointer transition-all hover:bg-slate-400 text-white px-4 inline-flex py-1.5 rounded-full  gap-3 items-center">
                <span>TEST</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2.5}
                  stroke="currentColor"
                  className="w-4 h-5 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </a>
            </div>
            <div>
              <a className="bg-[#143588] text-sm cursor-pointer transition-all hover:bg-slate-400 text-white px-4 inline-flex py-1.5 rounded-full  gap-3 items-center">
                <span>SEND</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2.5}
                  stroke="currentColor"
                  className="w-4 h-5 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Mail;
