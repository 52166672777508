//create a react functional component in typescript
import { useEffect, useState, type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Contact from './contact';

interface contactListProps {
  refreshCounter: number;
}

const ContactList: FC<contactListProps> = ({ refreshCounter }) => {
  const [contacts, setContacts] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);

  const getContacts = async () => {
    setLoading(true);

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');

    user.email &&
      (await fetch(process.env.REACT_APP_API_URL + '/get-contacts.php', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + storedJWT,
        },
        body: JSON.stringify({
          email: user.email,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setContacts(data);
          setLoading(false);
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          setLoading(false);
        }));
  };

  //create the function to create a folder

  useEffect(() => {
    getContacts();
  }, [refreshCounter]);

  return (
    <div className="min-h-[300px]  p-3 lg:py-10 md:pl-5 lg:pl-10 xl:pl-16 ">
      {loading ? (
        <div className="flex justify-center">
          <svg
            className="inline-block mt-5 w-9 h-9 text-accent animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      ) : (
        <div className="max-h-[60vh] max-w-full relative overflow-x-scroll border-b border-slate-100 pb-5">
          <ul role="list" className="divide-y divide-gray-100">
            {Array.isArray(contacts) &&
              contacts.map((contact) => {
                return <Contact key={contact.ID} contact={contact} />;
              })}
          </ul>
        </div>
      )}
    </div>
  );
};
export default ContactList;
