import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import AppContext from './utils/appContext';
import Layout from './components/layout';

import Files from './pages/files';
import Members from './pages/members';
import Contacts from './pages/contacts';
import ContactPage from './pages/contactpage';
import Products from './pages/products';
import ProductPage from './pages/productpage';
import Groups from './pages/groups';
import Mail from './pages/mail';
import Bin from './pages/bin';
import ActivateAccount from './pages/activate-account';
import RequestPasswordReset from './pages/request-password-reset';
import ResetPassword from './pages/reset-password';
import Login from './pages/login';

function App() {
  const [isLoggedIn, toggleLoggedIn] = useState<boolean>(false);
  const [orderList, setOrderList] = useState<any[]>([]);

  const updateLoginStatus = (status: boolean) => {
    toggleLoggedIn(status);
  };
  const addToOrderList = (order: any) => {
    const currentOrderList = orderList;

    if (orderList.length === 0) {
      setOrderList([...orderList, order]);
    } else {
      const index = currentOrderList.findIndex(
        (o) => o.product.ID === order.product.ID
      );

      if (index === -1) {
        setOrderList([...orderList, order]);
      } else {
        const originalQuantiy = currentOrderList[index].quantity;
        const newQuantity = originalQuantiy + order.quantity;

        // Create a new array with the updated item
        const newOrderList = currentOrderList.map((item, i) =>
          i === index ? { ...item, quantity: newQuantity } : item
        );

        setOrderList(newOrderList);
      }
    }
  };
  const removeFromOrderList = (product_id: number) => {
    if (orderList.length === 0) return;
    const found = orderList.some((o) => o.product.ID === product_id);
    if (found) {
      setOrderList(orderList.filter((o) => o.product.ID !== product_id));
    }
  };
  const emptyOrderList = () => {
    setOrderList([]);
  };

  return (
    <AppContext.Provider
      value={{
        isLoggedIn,
        updateLoginStatus,
        orderList,
        addToOrderList,
        removeFromOrderList,
        emptyOrderList,
      }}
    >
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/activate-account" element={<ActivateAccount />}></Route>
        <Route
          path="/request-password-reset"
          element={<RequestPasswordReset />}
        ></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route
          path="*"
          element={
            <Layout>
              <Routes>
                <Route path="/" element={<Files />}></Route>
                <Route path="/members" element={<Members />}></Route>
                <Route path="/contacts" element={<Contacts />}></Route>
                <Route path="/products" element={<Products />}></Route>
                <Route path="/products/:id" element={<ProductPage />}></Route>
                <Route path="/contacts/:id" element={<ContactPage />}></Route>
                <Route path="/groups" element={<Groups />}></Route>
                <Route path="/mail" element={<Mail />}></Route>
                <Route path="/bin" element={<Bin />}></Route>
              </Routes>
            </Layout>
          }
        />
      </Routes>
    </AppContext.Provider>
  );
}

export default App;
