import type { FC } from 'react';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserList from '../components/userlist';
import RegisterUser from '../components/registeruser';
import AppContext from '../utils/appContext';

//make an infterface for the props
interface MembersProps {}
const Members: FC<MembersProps> = ({}) => {
  const [counter, setCounter] = useState(0);
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const updateCounter = () => {
    setCounter(counter + 1);
  };
  const checkStatus = async () => {
    const storedJWT = localStorage.getItem('jwt');

    if (storedJWT) {
      await fetch(process.env.REACT_APP_API_URL + '/check-status.php', {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + storedJWT,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data == 'success') {
            console.log('success');
          } else {
            localStorage.clear();
            context?.updateLoginStatus(false);
            navigate('/login');
          }
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          localStorage.clear();
          context?.updateLoginStatus(false);
          navigate('/login');
        });
    } else {
      console.log('not logged in');
      localStorage.clear();
      context?.updateLoginStatus(false);
      navigate('/login');
    }
  };
  useEffect(() => {
    checkStatus();
  }, []);

  return (
    <>
      <UserList refreshCounter={counter} />
      <div className="max-w-3xl p-3 mb-10 md:px-5 lg:pl-10 xl:pl-16">
        <RegisterUser refresh={updateCounter} />
      </div>
    </>
  );
};
export default Members;
