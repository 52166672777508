import type { FC } from 'react';
import React, { useEffect, ChangeEvent, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../utils/appContext';
import axios from 'axios';

interface registercontactProps {
  refresh: () => void;
  refreshCounter: number;
}

const RegisterContact: FC<registercontactProps> = ({
  refresh,
  refreshCounter,
}) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [title, setTitle] = useState('');
  const [company, setCompany] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedContactLists, setSelectedContactLists] = useState<any>([]);
  const [contactLists, setContactsLists] = useState<any>([]);
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const refreshList = () => {
    refresh();
  };
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      setSelectedFiles(filesArray);
    }
  };
  const logout = () => {
    localStorage.clear();
    context?.updateLoginStatus(false);
    navigate('/login');
  };
  const emptyForm = () => {
    setFirstname('');
    setLastname('');
    setEmail('');
    setTitle('');
    setPhone('');
    setCompany('');
    setDescription('');
    setSelectedFiles([]);
    setSelectedContactLists([]);
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    const storedJWT = localStorage.getItem('jwt');
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('image', file); // Use "files[]" to handle multiple files
    });
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('email', email);
    formData.append('title', title);
    formData.append('phone', phone);
    formData.append('company', company);
    formData.append('contact_list', JSON.stringify(selectedContactLists));
    formData.append('description', description);

    if (storedJWT) {
      await axios
        .post(
          process.env.REACT_APP_API_URL + '/register-contact.php',
          formData,
          {
            headers: {
              Authorization: 'Bearer ' + storedJWT,
            },
          }
        )
        .then((response: any) => {
          if (response.data === 'Error decoding token: Expired token') {
            logout();
          }
          const jsonData = response.data;

          setLoading(false);
          if (jsonData.error.length > 0) {
            setError(jsonData.error.join('<br/>'));
          } else if (jsonData.success.length > 0) {
            setSuccess('Contact created successfully'); // Join success messages with newlines
            emptyForm();
            refreshList();
          } else {
            setError('Something went wrong, please try again.');
          }
        })
        .catch((e) => {
          console.log(e);
          setError('Failed, try again');
          setLoading(false);
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error?.response?.data?.error);

          setLoading(false);
        });
    } else {
      console.log('not logged in');
      localStorage.clear();
      context?.updateLoginStatus(false);
      navigate('/login');
    }
  };
  const getContactLists = async () => {
    const storedJWT = localStorage.getItem('jwt');
    await fetch(process.env.REACT_APP_API_URL + '/get-contact-lists.php', {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + storedJWT,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setContactsLists(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getContactLists();
  }, [refreshCounter]);

  return (
    <>
      <h2 className="mt-12 text-xl font-normal text-gray-900">ADD A CONTACT</h2>

      <div className="max-w-full pr-5 md:mt-4 md:max-w-lg">
        <div className="py-8 bg-white md:shadow md:px-4 md:rounded-lg ">
          <form className="space-y-3" onSubmit={handleSubmit}>
            <div className="grid gap-5 md:grid-cols-2">
              <div>
                <label
                  htmlFor="firstname"
                  className="block text-sm font-medium text-gray-700"
                >
                  Firstname
                </label>
                <div className="mt-1">
                  <input
                    id="firstname"
                    name="firstname"
                    type="text"
                    required
                    value={firstname}
                    onChange={(event) => setFirstname(event.target.value)}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="lastname"
                  className="block text-sm font-medium text-gray-700"
                >
                  Lastname
                </label>
                <div className="mt-1">
                  <input
                    id="lastname"
                    name="lastname"
                    type="text"
                    required
                    value={lastname}
                    onChange={(event) => setLastname(event.target.value)}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="grid gap-5 md:grid-cols-2">
              <div>
                <label
                  htmlFor="firstname"
                  className="block text-sm font-medium text-gray-700"
                >
                  E-mail
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    autoComplete="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone
                </label>
                <div className="mt-1">
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    required
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="grid gap-5 md:grid-cols-2">
              <div>
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Title
                </label>
                <div className="mt-1">
                  <input
                    id="title"
                    name="title"
                    type="text"
                    required
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Company
                </label>
                <div className="mt-1">
                  <input
                    id="company"
                    name="company"
                    type="text"
                    required
                    value={company}
                    onChange={(event) => setCompany(event.target.value)}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 mb-6">
              <label
                htmlFor="image"
                className="block mb-3 text-sm font-medium text-gray-700"
              >
                Image:
              </label>
              <input
                className="block w-full text-sm text-gray-700 file:mr-4 file:py-1.5 file:px-3 file:rounded-md file:border-0 file:text-sm file:bg-slate-200 file:text-slate-700 hover:file:bg-slate-100"
                type="file"
                name="image"
                id="image"
                onChange={handleFileChange}
              />
            </div>
            <div>
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                Description
              </label>
              <div className="mt-1">
                <textarea
                  id="description"
                  name="description"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="role"
                className="block text-sm font-medium text-gray-700"
              >
                Contact lists
              </label>
              <div className="mt-1">
                <select
                  id="contact_list"
                  name="contact_list"
                  required
                  multiple
                  value={selectedContactLists}
                  onChange={(event) => {
                    console.log('change');
                    const selectedOptions = Array.from(
                      event.target.selectedOptions,
                      (option) => option.value
                    );
                    setSelectedContactLists(selectedOptions);
                    // Debugging the selected options
                  }}
                  className={`text-default block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm`}
                >
                  <option disabled value="">
                    Select list
                  </option>

                  {Array.isArray(contactLists) &&
                    contactLists.map((list) => (
                      <option key={list.ID} value={list.ID}>
                        {list.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="pt-3">
              {loading ? (
                <div className="flex justify-center">
                  <svg
                    className="inline-blockmt-5 w-9 h-9 text-accent animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              ) : (
                <button
                  type="submit"
                  className="flex justify-center w-auto px-6 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-[#143588] hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                >
                  Register
                </button>
              )}
              <p className="mt-2 text-sm font-medium text-red-600">{error}</p>
              <p className="mt-2 text-sm font-medium text-green-600">
                {success}
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default RegisterContact;
