import type { FC } from 'react';
import React, { useEffect, useContext } from 'react';
import AppContext from '../utils/appContext';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import FileUpload from '../components/fileupload';
import CreateFolder from '../components/createfolder';
import FileBrowser from '../components/filebrowser';
import SearchFiles from '../components/searchfiles';
//files interface
interface filesProps {}
const Files: FC<filesProps> = ({}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );
  const [folderPath, setFolderPath] = useState<string>('/');
  const [counter, setCounter] = useState(0);
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [groups, setGroups] = useState<Array<any>>([]);
  const checkStatus = async () => {
    const storedJWT = localStorage.getItem('jwt');

    if (storedJWT) {
      await fetch(process.env.REACT_APP_API_URL + '/check-status.php', {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + storedJWT,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data == 'success') {
            console.log('success');
            setLoading(false);
          } else {
            localStorage.clear();
            context?.updateLoginStatus(false);
            navigate('/login');
          }
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          localStorage.clear();
          context?.updateLoginStatus(false);
          navigate('/login');
        });
    } else {
      console.log('not logged in');
      localStorage.clear();
      context?.updateLoginStatus(false);
      navigate('/login');
    }
  };
  const updateFolder = () => {
    console.log('update folder');
    setCounter((counter) => counter + 1);
  };
  const updateFolderPath = (path: string) => {
    setFolderPath(path);
  };
  const getgroups = async () => {
    const storedJWT = localStorage.getItem('jwt');
    await fetch(process.env.REACT_APP_API_URL + '/get-all-groups.php', {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + storedJWT,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setGroups(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if (user.groups?.includes(0) || user.groups?.includes(1)) {
      getgroups();
    }
    checkStatus();
  }, []);
  const roles = JSON.parse(user.groups || '[]');

  return (
    <>
      <div className="relative z-10 p-3 md:pt-10 md:p-5 lg:pl-10 xl:pl-16 ">
        <div className="flex ">
          <div className="w-full lg:w-[50%] xl:w-[40%] bg-[#143588] rounded-bl-xl rounded-tl-xl p-5 md:p-10 lg:p-10 xl:py-12 xl:px-16">
            <h2 className="py-2 text-base text-white md:text-xl ">
              Welcome {user.firstname},<br />
              browse our brand or product images
            </h2>
            <div className="my-3 max-w-[220px] ">
              <SearchFiles
                updateFolderPath={updateFolderPath}
                path={folderPath}
              />
            </div>
          </div>
          <div className="relative  hidden lg:block lg:w-[50%] overflow-hidden rounded-tr-xl rounded-br-xl xl:w-[60%]">
            <img
              src="/welcome-page.jpg"
              alt=""
              className="absolute object-cover w-full h-full "
            />
          </div>
        </div>
      </div>
      <FileBrowser
        folderPath={folderPath}
        changeFolderPath={updateFolderPath}
        refreshCounter={counter}
        groups={groups}
      />
      <div className="grid max-w-3xl gap-10 p-3 mb-10 lg:grid-cols-2 md:mb-0 md:px-5 lg:pl-10 xl:pl-16">
        {(roles.includes(0) || roles.includes(1)) && (
          <FileUpload
            updateFolder={updateFolder}
            folderPath={folderPath}
            groups={groups}
          />
        )}
        {(roles.includes(0) || roles.includes(1)) && (
          <CreateFolder
            updateFolderPath={updateFolderPath}
            folderPath={folderPath}
            groups={groups}
          />
        )}
      </div>
    </>
  );
};
export default Files;
