import type { FC } from 'react';
import React, { useState, ChangeEvent, useContext, useEffect } from 'react';
import AppContext from '../utils/appContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface editFolderProps {
  folder: any;
  closePopup: () => void;
  refresh: () => void;
}

const EditFolder: FC<editFolderProps> = ({ folder, closePopup, refresh }) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [folderName, setFolderName] = useState(folder.name);

  const [folderError, setFolderError] = useState('');
  const [folderSuccess, setFolderSuccess] = useState('');

  const context = useContext(AppContext);
  const navigate = useNavigate();

  const updateFolder = async () => {
    setLoading(true);
    setFolderError('');
    setFolderSuccess('');
    const storedJWT = localStorage.getItem('jwt');
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('image', file); // Use "files[]" to handle multiple files
    });

    formData.append('folder_name', folderName);
    formData.append('id', folder.ID);
    console.log(folderName, 'folderName');
    console.log(selectedFiles, 'selectedFiles');
    if (
      (folderName.trim() === '' && selectedFiles.length === 0) ||
      (folder.folder_name === folderName.trim() && selectedFiles.length === 0)
    ) {
      setFolderError('Set new folder name or image');
      setLoading(false);
    } else {
      console.log(formData.get('image'), formData.get('name'), 'formData');

      await axios
        .post(process.env.REACT_APP_API_URL + '/update-folder.php', formData, {
          headers: {
            Authorization: 'Bearer ' + storedJWT,
          },
        })
        .then((response: any) => {
          console.log(response);
          const jsonData = response.data;
          console.log(jsonData);
          if (jsonData.message) {
            setFolderSuccess(jsonData.message);
            if (jsonData.message === 'Folder updated successfully.') {
              setFolderName('');
              setFolderError('');
              setSelectedFiles([]);
              refresh();
              //wait 2 seconds and close the popup
              setTimeout(() => {
                closePopup();
              }, 2000);
            } else {
              setFolderError(jsonData.response.error);
            }
          } else if (jsonData.error) {
            setFolderError(jsonData.error);
          } else if (jsonData === 'Error decoding token: Expired token') {
            logout();
          }
          setLoading(false);
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error', error);
          console.log(error?.response?.data?.error);
          setFolderError(error?.response?.data?.error);
          setLoading(false);
        });
    }
  };
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      setSelectedFiles(filesArray);
    }
  };

  const logout = () => {
    localStorage.clear();
    context?.updateLoginStatus(false);
    navigate('/login');
  };

  return (
    <div
      className={` relative inline-block max-w-3xl mx-auto mt-5 bg-white rounded-lg md:shadow-md p-5 md:p-6`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        className="absolute z-10 cursor-pointer w-7 h-7 top-3 right-3 hover:stroke-red-300 stroke-red-600"
        onClick={closePopup}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
      <div className="relative">
        <h1 className="z-10 mb-4 text-xl font-bold text-gray-800">
          EDIT FOLDER
        </h1>
        <input
          className="block w-full px-2 py-1 text-gray-700 border border-gray-300 rounded-md"
          type="text"
          name="folder-name"
          placeholder="Folder name"
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
        />

        <div className="mt-4 mb-6">
          <label
            htmlFor="image"
            className="flex items-center block gap-3 mb-3 text-sm font-medium text-gray-700"
          >
            {folder?.base64 && (
              <img
                src={`data:image/jpeg;base64,${folder.base64}`}
                alt={folder.name}
                className="object-cover rounded w-7 h-7"
              />
            )}
            <span>New image:</span>
          </label>
          <input
            className="block w-full text-sm text-gray-700 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-slate-200 file:text-slate-700 hover:file:bg-slate-100"
            type="file"
            name="image"
            id="image"
            onChange={handleFileChange}
          />
        </div>
        {loading ? (
          <svg
            className="inline-block w-8 h-8 text-accent animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : (
          <button
            className="inline-flex justify-center w-auto px-4 py-2 mt-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-[#143588] hover:bg-[#d3d3cd] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#d3d3cd]"
            onClick={updateFolder}
          >
            Update
          </button>
        )}

        <p className="mt-2 text-sm font-medium text-green-600">
          {folderSuccess}
        </p>
        <p className="mt-2 text-sm font-medium text-red-600">{folderError}</p>
      </div>
    </div>
  );
};

export default EditFolder;
