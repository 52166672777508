import type { FC } from 'react';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../utils/appContext';

interface groupProps {
  refresh: () => void;
}

const CreateContactList: FC<groupProps> = ({ refresh }) => {
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const context = useContext(AppContext);
  const navigate = useNavigate();
  const refreshList = () => {
    refresh();
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    const storedJWT = localStorage.getItem('jwt');

    if (storedJWT) {
      await fetch(process.env.REACT_APP_API_URL + '/create-contact-list.php', {
        body: JSON.stringify({
          name,
        }),
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + storedJWT,
        },
        method: 'POST',
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.message == 'Contact list created successfully') {
            setSuccess(data.message);
            clearForm();
            refreshList();
          } else if (data.error == 'Contact list already exists') {
            setError('Contact list already exists');
          }
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setError('Failed, try again');
          setLoading(false);
        });
    } else {
      console.log('not logged in');
      localStorage.clear();
      context?.updateLoginStatus(false);
      navigate('/login');
    }
  };

  const clearForm = () => {
    setName('');
  };

  return (
    <>
      <h2 className="text-xl font-normal text-gray-900 uppercase md:mt-12">
        Create a contact list
      </h2>

      <div className="max-w-full pr-5 md:mt-8 md:max-w-lg">
        <div className="py-8 bg-white md:shadow md:px-4 md:rounded-lg ">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="grid gap-5 lg:grid-cols-4">
              <div className="lg:col-span-3">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <div className="mt-1">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    required
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>

            <div>
              {loading ? (
                <div className="flex justify-center">
                  <svg
                    className="inline-blockmt-5 w-9 h-9 text-accent animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              ) : (
                <button
                  type="submit"
                  className="flex justify-center w-auto px-6 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-[#143588] hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                >
                  Submit
                </button>
              )}
              <p className="mt-2 text-sm font-medium text-red-600">{error}</p>
              <p className="mt-2 text-sm font-medium text-green-600">
                {success}
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateContactList;
